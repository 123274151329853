import React from "react"
import { Link } from "gatsby"

import "./style.scss"

const ContentTypePagination = ({ previousPage, nextPage, contentType }) => {
  return (
    <nav
      className="pagination-single section-inner"
      aria-label={contentType}
      role="navigation"
    >
      <div className="pagination-single-inner">
        {previousPage && (
          <Link className="previous-post" to={previousPage.uri}>
            <span className="arrow" aria-hidden="true">
              ←
            </span>
            <div className="-wrapper">
              <p className="-unlink">Previous Post</p>
              <span className="-title">
                <span
                  className="title-inner"
                  dangerouslySetInnerHTML={{ __html: previousPage.title }}
                />
              </span>
            </div>
          </Link>
        )}

        {nextPage && (
          <Link className="next-post" to={nextPage.uri}>
            <div className="-wrapper">
              <p className="-unlink">Next Post</p>
              <span className="-title">
                <span
                  className="title-inner"
                  dangerouslySetInnerHTML={{ __html: nextPage.title }}
                />
              </span>
            </div>
            <span className="arrow" aria-hidden="true">
              →
            </span>
          </Link>
        )}
      </div>
    </nav>
  )
}

export default ContentTypePagination
