import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import './style.scss'

const AuthorBio = ({ author }) => {
  author = author?.node
  if (!author.description) return null

  const avatar = getImage(author?.avatar.imageFile)


  const description = author.description.replace(/(\r\n|\n|\r)/gm, "<br/>")

  return (
    <section className="author-bio">
      <header className="author-header">
        <div className="author-avatar vcard">
          <div className="avatar avatar-160 wp-user-avatar wp-user-avatar-160 alignnone photo">
            <GatsbyImage
              image={avatar}
              className="avatar"
              alt={author.name} />
          </div>
        </div>
        <div className="author-wrapper">
        <p className="-title">The Author</p>
        <Link className="author-link" to={author.uri} rel="author">
          {author.name}
        </Link>
        </div>
      </header>
      <div className="author-description">
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </section>
  )
}

export default AuthorBio