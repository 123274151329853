import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { MdCopyright } from "@react-icons/all-files/md/MdCopyright"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ContentTypePagination from "../../components/contentTypePagination"
import AuthorBio from "../../components/authorBio"
import PostMeta from "../../components/postMeta"
import PostCategories from "../../components/postCategories"
import FeaturedImage from "../../components/featuredImage"

import "../../assets/Scss/templates/posts.scss"

const Post = ({ data }) => {
  const { nextPage, previousPage, page } = data
  const {
    title,
    uri,
    content,
    featuredImage,
    categories,
    excerpt,
    databaseId,
    author,
    date,
  } = page

  useEffect(() => {
    if (content.length !== 0) {
      const iFrames = document.querySelectorAll("iframe")
      for (var i = 0; i < iFrames.length; i++) {
        iFrames[i].parentNode.classList.add("-iframe")
      }
    }
    return () => {
      if (content.length === 0) {
        const iFrames = document.querySelectorAll("iframe")
        for (var i = 0; i < iFrames.length; i++) {
          iFrames[i].parentNode.classList.remove("-iframe")
        }
      }
    }
  }, [content])

  return (
    <Layout
      bodyClass={`post-template-default single single-post postid-${databaseId} single-format-standard wp-embed-responsive singular has-post-thumbnail has-single-pagination showing-comments footer-top-visible customize-support`}
    >
      <Seo
        title={title}
        description={excerpt}
        socialImage={featuredImage?.node}
        uri={uri}
      />

      <article
        className={`post-${databaseId} post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized`}
        id={`post-${databaseId}`}
      >
        <header className="entry-header has-text-align-center header-footer-group">
          <div
            className="theBG"
            style={
              {
                // backgroundImage: `url(${featuredImage?.node.localFile.childImageSharp.gatsbyImageData.images.fallback.src})`,
              }
            }
          >
            <FeaturedImage image={featuredImage} />
          </div>
          <div className="container head-wrapper">
            <div className="entry-header-inner section-inner medium">
              <PostCategories categories={categories} />
              <h1
                className="entry-title"
                dangerouslySetInnerHTML={{ __html: title }}
              />
              {/* <div
                className="intro-text section-inner max-percentage small"
                dangerouslySetInnerHTML={{ __html: excerpt }}
              /> */}
              <PostMeta title={title} author={author} date={date} />
            </div>
            <div className="featured-wrapper">
              <FeaturedImage image={featuredImage} />
              <div className="caption">
                {featuredImage.node.caption ? <MdCopyright /> : ""}
                <div
                  dangerouslySetInnerHTML={{
                    __html: featuredImage.node.caption,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </header>

        <div className="container post-inner thin">
          <div
            className="entry-content"
            dangerouslySetInnerHTML={{
              __html: content.replace(/<p>&nbsp;<[/]p>/g, ""),
            }}
          />
        </div>

        <div className="container section-inner">
          <AuthorBio author={author} />
          <ContentTypePagination
            previousPage={previousPage}
            nextPage={nextPage}
            contentType={"Post"}
          />
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query post($id: String!, $nextPage: String, $previousPage: String) {
    page: wpPost(id: { eq: $id }) {
      ...PostContent
    }
    nextPage: wpPost(id: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpPost(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`

export default Post
